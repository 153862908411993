import TeamsSettingsGroupOverviewList from "./list";
import TeamsSettingsGroupOverviewShow from "./show";


if (document.body.getAttribute("data-current-path") === "teams/settings/time_entry_groups") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new TeamsSettingsGroupOverviewList();
    } else if(action === "show") {
        new TeamsSettingsGroupOverviewShow();
    }
}